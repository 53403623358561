import { useState, useEffect, ReactNode } from "react";
import { PromoSection } from "../components";
import { Row, Col } from "antd";
import { FinancialBrand, Step } from "@dreambigger/shared/src/types";
import Image from "next/image";
import { useRouter } from "next/router";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { FlowRenderOptions } from "../pages/flows/[flowId]";

export type StepLayoutProps = {
  step: Step;
  brand: FinancialBrand;
  children: ReactNode;
  renderOptions: FlowRenderOptions;
};

const stepBackgroundDefaults = {
  background: "",
  backgroundSize: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
};

export default function StepLayout({
  step,
  brand,
  renderOptions,
  children,
}: StepLayoutProps) {
  const { hideHeader = false } = renderOptions;
  const [stepBackground, setStepBackground] = useState(stepBackgroundDefaults);
  const [logoPosition, setLogoPosition] = useState("left bottom");
  const { type, assets } = step;
  const router = useRouter();
  const segment = useSegment();
  const {
    use3DCardInAcquire,
    acquireLogoHeight,
    primaryLogoHeight,
    primaryLogoUrl,
    primaryLogo,
  } = brand;

  const trackAnalytics = () => {
    segment.track({ action: "Logo Click", label: `Logo - Start` });
  };

  const cardClasses =
    "ph-3 md_ph-6 lg_ph-7 xl_ph-9 pv-4 bg-gray-1 br-2 z-1 s-7 mb-5 relative lg_w-46vw lg_right-5";
  const defaultClasses =
    "ph-3 md_pl-6 lg_pl-7 xl_pl-7 md_pr-7 lg_pr-8 xl_pr-8 pv-4 lg_pv-6 bg-gray-1 z-1 s-7 btlr-4 relative lg_w-47vw lg_right-3";
  const cardStyle = { minHeight: "93vh" };
  const defaultStyle = { minHeight: "100vh" };

  // On component load and step change, check if the background should be updated.
  useEffect(() => {
    // Set a confetti background for the success step and reposition logo
    if (step.type === "success" && router.query.submission === "complete") {
      setStepBackground({
        background:
          "https://res.cloudinary.com/cotribute/image/upload/v1656016216/acquire/template_assets/confetti.png",
        backgroundSize: "105%",
        backgroundRepeat: "repeat-x",
        backgroundPosition: "left 10%",
      });
      setLogoPosition("bottom center");
      // Else, reset the background, assuming that it isn't already reset.
    } else if (stepBackground.background !== "") {
      setStepBackground(stepBackgroundDefaults);
    }
  }, [step, router.query]);

  return (
    <Row style={{ overflow: "hidden" }}>
      {/* LEFT COLUMN --- PROMO SECTION */}
      <Col xs={0} lg={13} className="z-1">
        <PromoSection
          heroImage={assets.heroImage}
          heroText={assets.heroText}
          heroLogo={assets.heroLogo}
          // Ensure that the mobile-only PromoSection notification message will never display on certain step types.
          showMobileNotification={
            !(type === "payment-status" || type === "success")
          }
          heroImageBlockHtml={assets.heroImageBlockHtml}
          heroTextBlockHtml={assets.heroTextBlockHtml}
        />
      </Col>

      {/* RIGHT COLUMN --- STEP SECTION */}
      {/* Background */}
      <Col
        xs={24}
        lg={11}
        className={use3DCardInAcquire ? "bg-primary-light pv-2 lg_pv-5" : ""}
        style={{
          background: `url(${stepBackground.background})`,
          backgroundSize: stepBackground.backgroundSize,
          backgroundRepeat: stepBackground.backgroundRepeat,
          backgroundPosition: stepBackground.backgroundPosition,
        }}
      >
        {/* Card - Main Content */}
        <div
          className={use3DCardInAcquire ? cardClasses : defaultClasses}
          style={use3DCardInAcquire ? cardStyle : defaultStyle}
        >
          {/* Logo */}
          {!hideHeader && (
            <div
              className="relative min-h-5"
              style={{
                height: acquireLogoHeight || primaryLogoHeight || "24px",
              }}
            >
              {primaryLogoUrl ? (
                <a
                  href={primaryLogoUrl}
                  target="_blank"
                  rel="noreferrer"
                  onClick={trackAnalytics}
                >
                  <Image
                    src={primaryLogo}
                    alt="logo"
                    layout="fill"
                    objectFit="contain"
                    objectPosition={logoPosition}
                  />
                </a>
              ) : (
                <Image
                  src={primaryLogo}
                  alt="logo"
                  layout="fill"
                  objectFit="contain"
                  objectPosition={logoPosition}
                />
              )}
            </div>
          )}
          {/* StepElement - From [flowId].tsx */}
          {children}
        </div>
      </Col>
    </Row>
  );
}
