import { useState, useCallback, useEffect, useRef } from "react";
import { Form } from "@dreambigger/design-system/src/components";
import {
  PlaidIDVStepAssets,
  ResponseFields,
  Step,
} from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import styles from "./steps.module.scss";
import StepWrapper from "./step-wrapper";
import { useApplication, usePlaid } from "../api";
import { Button } from "@dreambigger/design-system/src/components";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CameraFilled,
  PlayCircleFilled,
  Loading3QuartersOutlined,
} from "@ant-design/icons";
import { message, Spin } from "antd";

import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnEvent,
} from "react-plaid-link";

const idvSessionIdKey = "idvSessionId";
const linkSessionIdKey = "linkSessionId";
const statusKey = "status";

enum Status {
  Pass = "pass",
  Fail = "fail",
  Shareable = "shareable",
}

const sessionEventToStatus: Record<string, string> = {
  IDENTITY_VERIFICATION_PASS_SESSION: "pass",
  IDENTITY_VERIFICATION_FAIL_SESSION: "fail",
};

const spinnerIcon = <Loading3QuartersOutlined spin />;

export default function PlaidIdv({ flow, step, brand, progress }: StepProps) {
  const {
    slug,
    type,
    assets: {
      templateUuid,
      helpText1,
      helpText2,
      termsText,
      ctaText,
      errorText,
      allowContinueOnError,
      maskError,
    },
  }: Omit<Step, "assets"> & { assets: PlaidIDVStepAssets } = step;

  // state
  const [status, setStatus] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [ready, setReady] = useState(false);
  const [disabled, setDisabled] = useState(true);

  // Ref to track if initialize has been run
  const initializeRef = useRef<boolean>(false);

  // custom hooks
  const [form] = Form.useForm();
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const plaidHelper = usePlaid(flow.financialInstitution.id);

  const initialize = async () => {
    try {
      const response = applicationHelper.findResponseField(
        flow.id,
        "draft",
        slug,
        "status"
      );
      if (typeof response === "string") {
        setStatus(response);
      } else {
        // If not completed, initialize Plaid
        if (!templateUuid) {
          message.error("Error initializing ID Verification");
          return;
        }

        const application = applicationHelper.find(flow.id, "draft");
        if (!application) {
          message.error("Unable to find application");
          return;
        }

        const idvSessionData = await plaidHelper.createLinkTokenforIDV({
          requestId: `${application.id}|${slug}`,
          templateUuid,
          onboardingApplicationId: application.id,
        });

        if (!idvSessionData) {
          message.error("Error initializing ID Verification");
          return;
        }

        // update the form field value for the ID Verification Session
        // clear out existing form values by using setFieldsValue
        form.setFieldsValue({ [idvSessionIdKey]: idvSessionData.id });

        if ("linkToken" in idvSessionData) {
          // initialize link session
          setToken(idvSessionData.linkToken);
        } else if (idvSessionData.isShareable === true) {
          changeStatus(Status.Shareable);
        }
      }
    } catch (error) {
      message.error("Error initializing ID Verification");
      console.error("Error initializing ID Verification", error);
    }
  };

  const changeStatus = async (newStatus: string) => {
    // on pass or fail, update the application with the response fields
    // otherwise if the user hits back instead of next, it will mess it up
    const application = applicationHelper.find(flow.id, "draft");
    if (!application) {
      message.error("Unable to find application");
      return;
    }

    // update the form field value for status
    form.setFieldValue(statusKey, newStatus);

    // Retrieve the current form field values
    const values = form.getFieldsValue();

    //remove empty fields
    const filteredResponse: ResponseFields = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        return;
      }

      filteredResponse[key] = values[key];
    });

    // update via API
    await applicationHelper.update(application.id, {
      slug,
      type,
      fields: filteredResponse,
    });

    // update state
    setStatus(newStatus);
  };

  // initialize when component mounts
  useEffect(() => {
    if (!applicationHelper.isValidating && !initializeRef.current) {
      initialize();
      initializeRef.current = true;
    }
  }, [applicationHelper.isValidating]);

  // Initialize Plaid Link when linkToken is set
  useEffect(() => {
    if (token) {
      setReady(true);
    }
  }, [token]);

  // triggered by both onSuccess and onEvent handlers, as well as init code
  useEffect(() => {
    if (!status) {
      return;
    }

    setReady(true);

    if (
      status === Status.Pass ||
      status === Status.Shareable ||
      (status === Status.Fail && allowContinueOnError)
    ) {
      setDisabled(false);
    }
  }, [status]);

  // Plaid IDV callbacks
  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (
      _publicToken: string,
      { link_session_id: newLinkSessionId }: PlaidLinkOnSuccessMetadata
    ) => {
      // update the form field value for the Link Session
      form.setFieldValue(linkSessionIdKey, newLinkSessionId);
    },
    [form]
  );

  const onEvent = useCallback<PlaidLinkOnEvent>(
    async (eventName: string) => {
      const newStatus = sessionEventToStatus[eventName];
      if (newStatus) {
        changeStatus(newStatus);
      }
    },
    [form, changeStatus]
  );

  const { open: launchPlaid, ready: plaidIsReady } = usePlaidLink({
    token,
    onSuccess,
    onEvent,
  });

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    disabled,
    processing: !ready,
  };

  if (!templateUuid) {
    return (
      <p>
        There was an error loading this page. Please contact support to finish
        your application.
      </p>
    );
  }

  return (
    <StepWrapper {...wrapperProps}>
      <Form.Item name={idvSessionIdKey} hidden={true} />
      <Form.Item name={linkSessionIdKey} hidden={true} />
      <Form.Item name={statusKey} hidden={true} />

      {!plaidIsReady && !status && <Spin indicator={spinnerIcon} />}

      {(plaidIsReady || !!status) && (
        <>
          {helpText1 && (
            <div>
              <p
                className={`${styles.textLinks} f-4 gray-8 lh-4`}
                dangerouslySetInnerHTML={{
                  __html: helpText1 ?? "",
                }}
              />
            </div>
          )}

          <div className={`flex flex-column justify-center items-center pt-4`}>
            {/* If verified display the result. Otherwise give option to load IDV */}{" "}
            {status === null && (
              <Button
                type="primary"
                className="f-5 h-7 md_f-6 md_h-8 s-2 mb-7 lift"
                onClick={() => launchPlaid()}
                disabled={!plaidIsReady}
              >
                <CameraFilled /> {ctaText ?? "Verify Using Your Webcam"}
              </Button>
            )}
            {status === Status.Shareable && (
              // Message displayed on url sent.
              <div style={{ color: "green" }}>
                <div className="flex items-center lh-2 mb-7">
                  <PlayCircleFilled className="f-7" />
                  <div className="flex flex-column ml-2">
                    <p className="mb-0 f-7">Verification In Progress</p>
                    <p className="fwb mb-0">
                      Please check your email for instructions on how to verify
                      your identity.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {(status === Status.Pass || maskError) && (
              // Message displayed on success or hiding error
              <div
                className="flex items-center f-6 md_f-7 mb-7"
                style={{ color: "green" }}
              >
                <CheckCircleFilled />
                <p className="ml-2 mb-0">Verification Completed</p>
              </div>
            )}
            {status === Status.Fail && (
              // Message displayed on failure.
              <div style={{ color: "red" }}>
                <div className="flex items-center lh-2 mb-7">
                  <CloseCircleFilled className="f-7" />
                  <div className="flex flex-column ml-2">
                    <p className="mb-0 f-7">
                      Verification Could Not Be Completed
                    </p>
                    <p className="fwb mb-0">
                      {errorText ||
                        "Please go back to the previous step and verify your ID through another option."}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {helpText2 && (
            <div>
              <p
                className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
                dangerouslySetInnerHTML={{
                  __html: helpText2,
                }}
              />
            </div>
          )}

          {termsText && (
            <div>
              <p
                className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
                dangerouslySetInnerHTML={{
                  __html: termsText,
                }}
              />
            </div>
          )}
        </>
      )}
    </StepWrapper>
  );
}
