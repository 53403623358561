import { Col } from "antd";
import Image from "next/image";
import { FinancialBrand } from "@dreambigger/shared/src/types";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { PageHeader } from "@ant-design/pro-components";

export interface HeaderProps {
  brand: FinancialBrand;
  offset?: number;
}

export default function Header({ brand, offset = 1 }: HeaderProps) {
  const segment = useSegment();

  const trackAnalytics = () => {
    segment.track({ action: "Logo Click", label: `Logo - Start` });
  };

  return (
    <Col
      offset={offset}
      style={{
        padding: "10px 0",
      }}
    >
      <PageHeader>
        <div
          className="min-h-5 relative"
          style={{
            height:
              brand.acquireLogoHeight || brand.primaryLogoHeight || "24px",
            width: "auto",
            position: "relative",
          }}
        >
          {brand.primaryLogoUrl ? (
            <a
              href={brand.primaryLogoUrl}
              target="_blank"
              onClick={trackAnalytics}
              rel="noreferrer"
            >
              <Image
                src={brand.primaryLogo}
                alt="logo"
                layout="fill"
                objectFit="contain"
                objectPosition="left top"
              />
            </a>
          ) : (
            <Image
              src={brand.primaryLogo}
              alt="logo"
              layout="fill"
              objectFit="contain"
              objectPosition="left top"
            />
          )}
        </div>
      </PageHeader>
    </Col>
  );
}
