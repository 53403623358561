import {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  ReactNode,
} from "react";
import { message, FormInstance, Spin } from "antd";
import { Loading3QuartersOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Form } from "@dreambigger/design-system/src/components";
import { StepContentSection } from "../components";
import { useApplication } from "../api";
import { useSegment } from "@dreambigger/shared/src/hooks";
import dayjs from "dayjs";
import { prefixFieldsWithSlug } from "../utils/prefixStepSlug";
import useNextStep from "../hooks/use-next-step";
import { useScreenshot } from "../api";

import {
  Step,
  Flow,
  FinancialBrand,
  ResponseFields,
  OnboardingApplication,
  Hook,
} from "@dreambigger/shared/src/types";
import { changeStepForward } from "../utils/changeStepForward";

export type StepWrapperProps = {
  step: Step;
  brand: FinancialBrand;
  flow: Flow;
  progress: number;
  form: FormInstance;
  disabled: boolean;
  processing?: boolean;
  handleNext?: any;
  handleInput?: (changedValues: ResponseFields, values: ResponseFields) => void;
  handlePrefill?: (
    initialValues?: ResponseFields
  ) => ResponseFields | undefined;
  children: ReactNode;
};

export default function StepWrapper({
  step,
  brand,
  flow,
  progress,
  form,
  handleNext,
  handleInput,
  handlePrefill,
  disabled,
  processing,
  children,
}: StepWrapperProps) {
  const [saving, setSaving] = useState(processing || false);
  const { slug, type, assets } = step;
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const screenshotHelper = useScreenshot(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );
  const response = useMemo(
    () => applicationHelper.findResponse(flow.id, "draft", slug),
    [application, flow, step]
  );
  const prefillValues = useMemo(
    () => (handlePrefill ? handlePrefill(response?.fields) : response?.fields),
    [response, handlePrefill]
  );
  const segment = useSegment();
  const isHTMLStep = step.type === "html";

  // Keep track if there is a condition that needs to run before the step loads.
  const [beforeHooksLoaded, setBeforeHooksLoaded] = useState(false);
  const [beforeHooksError, setBeforeHooksError] = useState(false);

  // Uncomment this for easy troubleshooting of the application when steps change
  // useEffect(() => {
  //   console.log("application", application), [application];
  // }, []);

  useEffect(() => {
    setSaving(!!processing);
  }, [processing]);

  const nextStep = useNextStep(assets, applicationHelper, flow);

  const screenshotArea = useRef(null);

  // Capture screenshot of the step if enabled in step assets
  const saveScreenshot = () => {
    if (!application) return;
    if (assets.captureScreenshot) {
      screenshotHelper.capture(application.id, screenshotArea, slug);
    }
  };

  // Run hooks specified in the beforeHooks or afterHooks of the step.
  const runHooks = async ({
    hooks,
    applicationHelper,
    application,
  }: {
    hooks: Hook[];
    applicationHelper: ReturnType<typeof useApplication>;
    application: OnboardingApplication;
  }) => {
    /**
     * Retries the callback function based on the retryCount specified in the hook.
     * If the retryCount is reached, an error message is displayed and the error is thrown.
     *
     * @param hook
     * @param callback
     */
    const retryHandler = async ({
      hook,
      callback,
    }: {
      hook: Hook;
      callback: () => Promise<void>;
    }) => {
      let retryCount = hook.retryCount || 1;

      let attempts = 0;
      let success = false;

      while (attempts < retryCount && !success) {
        try {
          await callback();
          success = true;
        } catch (error) {
          attempts += 1;

          if (attempts >= retryCount) {
            message.error("Something went wrong. Please try again.");
            throw error;
          }
        }
      }
    };

    /**
     * Runs the corelation credit pull hook.
     *
     * @param hook
     * @param application
     */
    const handleCorelationCreditPull = async ({
      application,
    }: {
      application: OnboardingApplication;
      hook: Hook;
    }) => {
      const { data: creditReports } =
        await applicationHelper.pullCorelationCredit(application.id);

      const promises = creditReports
        .filter((cr) => !cr.received)
        .map(async ({ id }) => {
          const DELAY_MS = 3000;
          const MAX_ATTEMPTS = 10;
          const delay = () =>
            new Promise((resolve) => setTimeout(resolve, DELAY_MS));

          let attempts = 0;

          while (attempts < MAX_ATTEMPTS) {
            // Get the latest financial app credit report
            const { data: creditReport } =
              await applicationHelper.getFinancialApplicationCreditReport(id);

            if (creditReport.received) {
              return true;
            }

            attempts++;
            await delay();
          }

          return false;
        });

      const results = await Promise.allSettled(promises);
      let hasErrors = false;
      let missingReports = false;
      results.forEach((result) => {
        if (result.status === "rejected") {
          hasErrors = true;
        } else if (result.value === false) {
          missingReports = true;
        }
      });

      if (hasErrors) {
        throw new Error("Failed to run corelation credit pull hook.");
      }

      if (missingReports) {
        throw new Error("Failed to pull data for corelation credit pull.");
      }
    };

    // Define a map of hook names to their corresponding functions
    const hookHandlers: Record<string, (hook: Hook) => Promise<void>> = {
      corelation_credit_pull: async (hook: Hook) => {
        await retryHandler({
          hook,
          callback: () =>
            handleCorelationCreditPull({
              application,
              hook,
            }),
        });
      },

      // Add more hooks here as needed
    };

    // Filter hooks to include only those with IDs
    const validHooks = hooks.filter((hook) => hook.slug);

    // Process each hook and handle it based on the map
    const hookPromises = validHooks.map(async (hook) => {
      try {
        const handler = hookHandlers[hook.name];

        if (handler) {
          await handler(hook);
        } else {
          console.warn(`Unrecognized hook: ${hook.name}`);
        }

        return { hookSlug: hook.slug, status: "fulfilled" };
      } catch (error) {
        throw {
          hookSlug: hook.slug,
          status: "rejected",
          reason: error instanceof Error ? error.message : error,
        };
      }
    });

    return Promise.allSettled(hookPromises);
  };

  const defaultHandleNext = useCallback(
    (values: any) => {
      // Prefix step slug followed by double underscore infront of fieldName
      const prefixedProperties = prefixFieldsWithSlug({ fields: values, slug });

      //remove empty fields
      const filteredResponse: ResponseFields = {};
      Object.keys(values).forEach((key) => {
        if (values[key] === undefined) {
          return;
        }

        // Format the dayjs object sent to the BE as YYYY-MM-DD while ignoring timezone using the offset.
        // DatePicker will be able to display the date even without the timezone.
        if (dayjs.isDayjs(values[key])) {
          filteredResponse[key] = values[key]
            .utcOffset(0, true)
            .format("YYYY-MM-DD");
          return;
        }

        filteredResponse[key] = values[key];
      });

      if (!application) {
        message.error("Unable to find application");
        return;
      }

      // Run after hookks if they exist for the step
      const runAfterHooks = async () => {
        const hooks = step.assets?.afterHooks?.hooks;

        if (Array.isArray(hooks) && hooks.length) {
          return runHooks({
            hooks,
            applicationHelper,
            application,
          });
        }
      };

      // TODO: move this into changeStepForward or useNextStep
      // so components that override handleNext can still use it
      const finalize = () => {
        // save screenshot
        saveScreenshot();
        // navigate to next step
        if (assets.runTransitionRules) {
          applicationHelper
            .getNextStep({
              applicationId: application.id,
              stepSlug: slug,
              transformUUID:
                typeof assets.runTransitionRules === "object"
                  ? assets.runTransitionRules.transformUUID
                  : undefined,
            })
            .then(({ data }) =>
              data.nextStep
                ? changeStepForward(data.nextStep)
                : nextStep(values)
            );
        } else {
          nextStep(values);
        }
      };

      // TODO: Figure out how to get after hooks to run when there are no fields to save (currently it will not wait for after hooks to finish - even if awaiting)
      if (Object.keys(filteredResponse).length === 0) {
        finalize();
        return;
      }

      setSaving(true);

      applicationHelper
        .update(application.id, {
          slug,
          type,
          fields: filteredResponse,
        })
        .then(async () => {
          try {
            await runAfterHooks();
          } catch (error) {
            console.error("Failed to run after hooks.");
          }
        })
        .then(finalize)
        .finally(() => {
          setSaving(false);

          // Track submission in segment
          segment.track({
            action: "Button Click",
            label: `Submit - ${slug}`,
            properties: prefixedProperties,
          });
        });
    },
    [application, step]
  );

  useEffect(() => {
    if (!handleInput) {
      return;
    }
    form.resetFields();
    handleInput(prefillValues || {}, prefillValues || {});
  }, [prefillValues]);

  useEffect(() => {
    if (!application) {
      return;
    }

    /**
     * Checks the beforeHooks for the step.
     * If there are no beforehooks, set the beforeHooksLoaded state to true.
     * If there are beforehooks, run the hooks specified in the beforehooks.
     * If a hook fails, set the beforeHooksError state to true.
     * If all hooks are successful, set the beforeHooksLoaded state to true.
     */
    const checkBeforeHooks = async () => {
      const hooks = step.assets?.beforeHooks?.hooks;

      if (Array.isArray(hooks) && hooks.length) {
        try {
          const beforeHookResults = await runHooks({
            hooks,
            applicationHelper,
            application,
          });

          // Keeps track of which hooks succeeded/failed
          const beforeHookSlugStatuses: { [key: string]: boolean } = {};

          beforeHookResults.forEach((result, index) => {
            if (result.status === "fulfilled") {
              beforeHookSlugStatuses[hooks[index].slug] = true;
            } else {
              console.error(`Hook ${hooks[index].slug} rejected`);
              beforeHookSlugStatuses[hooks[index].slug] = false;
            }
          });

          // TODO: Use beforeHookSlugStatuses to determine what should happen based on Hooks that failed/succeeded
          // For now, if any Hooks failed, set the beforeHooksError state to true
          if (Object.values(beforeHookSlugStatuses).some((status) => !status)) {
            setBeforeHooksError(true);
            return;
          }

          setBeforeHooksLoaded(true);
        } catch (error) {
          setBeforeHooksError(true);
        }
      } else {
        setBeforeHooksLoaded(true);
      }
    };

    if (assets.beforeHooks) {
      checkBeforeHooks();
    }
  }, [application, step.assets.beforeHooks]);

  // If the step is still loading and there is a beforehook to run, display a loading spinner.
  if (assets.beforeHooks && !beforeHooksLoaded && !beforeHooksError) {
    return (
      <StepContentSection
        brand={brand}
        stepProgress={progress}
        title={""}
        description={""}
        previousSlug={assets.previousSlug}
        hideProgressBar={assets.hideProgressBar}
      >
        <Spin indicator={<Loading3QuartersOutlined spin />}></Spin>
      </StepContentSection>
    );
  }

  // If there was an error fetching the beforeHook, display an error message.
  if (beforeHooksError) {
    return (
      <StepContentSection
        brand={brand}
        stepProgress={progress}
        title={assets.beforeHooks?.errorTitle ?? "Error"}
        description={assets.beforeHooks?.errorDescription ?? ""}
        previousSlug={assets.previousSlug}
        hideProgressBar={assets.hideProgressBar}
      >
        <div className="flex flex-column gray-8">
          <p className="f-3">
            {assets.beforeHooks?.errorText ||
              "There was an issue loading this application."}
          </p>
        </div>
      </StepContentSection>
    );
  }

  return (
    <div ref={screenshotArea}>
      <StepContentSection
        brand={brand}
        stepProgress={progress}
        title={assets.title ?? ""}
        description={assets.description ?? ""}
        previousSlug={assets.previousSlug}
        hideProgressBar={assets.hideProgressBar}
      >
        <Form
          form={form}
          onFinish={handleNext || defaultHandleNext}
          initialValues={prefillValues}
          onValuesChange={handleInput}
          onChange={() => handleInput}
        >
          {children}
          <div className={`${!isHTMLStep && `bt b-lightPrimary`} mt-6 pt-4`}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="s-2 lift h-7 ph-5"
                disabled={disabled}
                loading={saving}
              >
                {assets.submitButtonText} <RightOutlined />
              </Button>
            </Form.Item>
          </div>
        </Form>
      </StepContentSection>
    </div>
  );
}
