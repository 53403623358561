import { useState, useCallback, useMemo } from "react";
import { message, Row, Col, Input } from "antd";
import {
  Form,
  DatePicker,
  Typography,
} from "@dreambigger/design-system/src/components";
import {
  ResponseFields,
  CIFLocatorStepAssets,
} from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import StepWrapper from "./step-wrapper";
import dayjs from "dayjs";
import { useApplication } from "../api";
import { useAuthToken, useSegment } from "@dreambigger/shared/src/hooks";
import { prefixFieldsWithSlug } from "../utils/prefixStepSlug";
import { changeStepForward } from "../utils/changeStepForward";
import { api, CoreBankingApi } from "@dreambigger/shared/src/api/acquire";
import { isValidSsn } from "../utils/validators";

const firstNameKey = "firstName";
const lastNameKey = "lastName";
const ssnKey = "ssn";
const birthdayKey = "birthday";
const personIdKey = "personId";

const { Text } = Typography;

const isValidSsnLast4 = (ssnLast4: string) => {
  return ssnLast4.match(/\b\d{4}\b/g);
};

const CIFLocator = ({ flow, step, brand, progress }: StepProps) => {
  const [disabled, setDisabled] = useState(true);
  const [verifyFailed, setVerifyFailed] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [form] = Form.useForm();
  const segment = useSegment();

  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );

  const tokenHelper = useAuthToken(flow.financialInstitution.id);

  const { slug, type } = step;
  const {
    coreBankingConfigurationUUID,
    firstNameFilter,
    firstNameTitle,
    firstNamePlaceholder,
    lastNameFilter,
    lastNameTitle,
    lastNamePlaceholder,
    partialSsnFilter,
    ssnTitle,
    ssnPlaceholder,
    dobFilter,
    dobTitle,
    dobPlaceholder,
    dobConfig,
    verifyFailedMessage,
    nextSlug,
  } = step.assets as CIFLocatorStepAssets;

  const handleInput = (
    _changedValues: ResponseFields,
    values: ResponseFields
  ) => {
    setDisabled(
      !(
        (firstNameFilter !== true || values[firstNameKey]) &&
        (lastNameFilter !== true || values[lastNameKey]) &&
        (lastNameFilter !== true || values[birthdayKey]) &&
        values[ssnKey] &&
        (partialSsnFilter === true
          ? isValidSsnLast4(values[ssnKey].toString())
          : isValidSsn(values[ssnKey].toString()))
      )
    );
  };

  // Upon load, prefill inputs with stored responses.
  const handlePrefill = useCallback((initialValues?: ResponseFields) => {
    if (!initialValues) {
      return;
    }

    // Define modifiedValues const to be returned in place of initialValues.
    const modifiedValues: ResponseFields = {};

    for (const field in initialValues) {
      // DatePicker component requires a dayjs object instead of a string, so convert.
      if (field === birthdayKey) {
        modifiedValues[birthdayKey] = dayjs(
          initialValues[birthdayKey] as string
        );
      } else {
        modifiedValues[field] = initialValues[field];
      }
    }

    return modifiedValues;
  }, []);

  const nextStep = useCallback(
    (inputValues: any) => {
      changeStepForward(nextSlug, inputValues);
    },
    [nextSlug]
  );

  const handleNext = useCallback(
    (values: any) => {
      if (!application) {
        message.error("Unable to find application");
        return;
      }
      if (!coreBankingConfigurationUUID) {
        message.error("Missing configuration");
        return;
      }

      setProcessing(true);

      const formattedBirthday = values[birthdayKey]
        .utcOffset(0, true)
        .format("YYYY-MM-DD");

      const coreApi = new CoreBankingApi(api, tokenHelper);
      coreApi
        .locateCIF({
          coreBankingConfigurationUUID,
          firstName: values[firstNameKey],
          lastName: values[lastNameKey],
          tin: values[ssnKey],
          birthday: formattedBirthday,
        })
        .then(({ data }) => {
          const response = {
            [firstNameKey]: values[firstNameKey],
            [lastNameKey]: values[lastNameKey],
            [ssnKey]: values[ssnKey],
            [birthdayKey]: formattedBirthday,
            [personIdKey]: data.id,
          };

          applicationHelper
            .update(application.id, {
              slug,
              type,
              fields: response,
            })
            .then(() => {
              nextStep(values);
            })
            .finally(() => {
              setProcessing(false);

              // Prefix step slug followed by double underscore infront of fieldName
              const prefixedProperties = prefixFieldsWithSlug({
                fields: response,
                slug,
              });

              // Track submission in segment
              segment.track({
                action: "Button Click",
                label: `Submit - ${slug}`,
                properties: prefixedProperties,
              });
            });
        })
        .catch(() => {
          setVerifyFailed(true);
          setProcessing(false);
        });
    },
    [application, step]
  );

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    handleInput,
    handlePrefill,
    handleNext,
    disabled,
    processing,
  };

  return (
    <StepWrapper {...wrapperProps}>
      <Row>
        <Col xs={24} md={16}>
          {firstNameFilter === true && (
            <>
              <label>{firstNameTitle}</label>
              <Form.Item name={firstNameKey}>
                <Input
                  placeholder={firstNamePlaceholder}
                  className="ao-bl-input"
                  onFocus={() => setVerifyFailed(false)}
                />
              </Form.Item>
            </>
          )}

          {lastNameFilter === true && (
            <>
              <label>{lastNameTitle}</label>
              <Form.Item name={lastNameKey}>
                <Input
                  placeholder={lastNamePlaceholder}
                  className="ao-bl-input"
                  onFocus={() => setVerifyFailed(false)}
                />
              </Form.Item>
            </>
          )}

          {dobFilter === true && (
            <>
              <label>{dobTitle}</label>
              <Form.Item
                name={birthdayKey}
                valuePropName={"value"}
                rules={[
                  () => ({
                    // Use antd's validator to get value of field
                    validator(_, date) {
                      // Return no validation errors for an empty input
                      if (!date) {
                        return Promise.resolve();
                      }
                      const validationOptions = dobConfig?.validation;
                      const formData = form.getFieldsValue();

                      // - VALIDATION CHECKS AGAINST SPECIFIED DATES IN JSON CONFIG -
                      // ***** Check that provided date is not earlier than a specified date *****
                      if (
                        validationOptions?.beforeDate &&
                        date.isBefore(dayjs(validationOptions.beforeDate))
                      ) {
                        return Promise.reject(
                          new Error(
                            `You must select a date after ${dayjs(
                              validationOptions.beforeDate
                            ).format("MM/DD/YYYY")}.`
                          )
                        );
                      }
                      // ***** Check that provided date is not later than a specified date *****
                      if (
                        validationOptions?.afterDate &&
                        date.isAfter(dayjs(validationOptions.afterDate))
                      ) {
                        return Promise.reject(
                          new Error(
                            `You must select a date before ${dayjs(
                              validationOptions.afterDate
                            ).format("MM/DD/YYYY")}.`
                          )
                        );
                      }

                      // - VALIDATION CHECKS AGAINST OTHER INPUTS ON THE CURRENT STEP -
                      const beforeFieldDate = validationOptions?.beforeFieldKey
                        ? formData[validationOptions?.beforeFieldKey]
                        : null;
                      const afterFieldDate = validationOptions?.afterFieldKey
                        ? formData[validationOptions?.afterFieldKey]
                        : null;

                      // ***** Check that provided date is not earlier than a specified input *****
                      if (beforeFieldDate && date.isBefore(beforeFieldDate)) {
                        return Promise.reject(new Error(`Invalid date range`));
                      }
                      // ***** Check that provided date is not later than a specified input *****
                      if (afterFieldDate && date.isAfter(afterFieldDate)) {
                        return Promise.reject(new Error("Invalid date range."));
                      }

                      // If all these checks pass, the entry is valid.
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  placeholder={dobPlaceholder}
                  className="w-100 ao-bl-picker"
                  config={dobConfig}
                  // TODO:ACQR-3829 - Replace moment.
                  // @ts-ignore
                  defaultPickerValue={
                    dobConfig?.defaultPickerDate &&
                    dayjs(dobConfig?.defaultPickerDate)
                  }
                />
              </Form.Item>
            </>
          )}

          <label>{ssnTitle}</label>
          <Form.Item
            name={ssnKey}
            valuePropName={"value"}
            // Use antd rules to display error message.
            // * Note: We're currently using our own custom disable logic as opposed to antd's, so this
            // * so this rule violation is ONLY for the error message, not for actually preventing the
            // * form from being submitted.
            rules={[
              () => ({
                // Use antd's validator to get value of field
                validator(_, value) {
                  if (
                    value && // if the field isn't empty...
                    !(partialSsnFilter === true
                      ? isValidSsnLast4(value)
                      : isValidSsn(value))
                  ) {
                    return Promise.reject(
                      new Error(
                        "Invalid social security number. Please try again."
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              minLength={partialSsnFilter === true ? 4 : 9}
              maxLength={partialSsnFilter === true ? 4 : 9}
              placeholder={ssnPlaceholder}
              className="ao-bl-input"
              onFocus={() => setVerifyFailed(false)}
            />
          </Form.Item>

          {verifyFailed && (
            <Text className="warning">
              {verifyFailedMessage || "Please enter your SSN and Birthday."}
            </Text>
          )}
        </Col>
      </Row>
    </StepWrapper>
  );
};

export default CIFLocator;
