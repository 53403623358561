import { Row, Col, Radio, FormInstance } from "antd";
import { formatNumber } from "../../utils/textFormatting";
import { HomeOutlined } from "@ant-design/icons";
import {
  Input,
  Form,
  AutoComplete,
  Checkbox,
  DatePicker,
  MaskedInput,
  InputNumber,
  Select,
} from "@dreambigger/design-system";
import CloudinaryUpload from "../CloudinaryUpload";
import {
  CustomFieldConfig,
  FinancialBrand,
  MembershipEligibility,
} from "@dreambigger/shared/src/types";
import { DollarCircleOutlined } from "@ant-design/icons";
import SliderWithDisplay from "../SliderWithDisplay";
import styles from "../../steps/steps.module.scss";
import {
  isValidAutoComplete,
  isValidPhoneNumber,
  isValidSsn,
  isValidRoutingNumber,
  antdValidateRegex,
} from "../../utils/validators";
import { formatAsNationalPhoneNum } from "../../utils/textFormatting";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { AddressAutocomplete, AddressFields } from "../AddressAutocomplete";
import { useFlow } from "../../api";
import dayjs from "dayjs";
import { convertFunctionStrings } from "../../utils/parsing";

export const addressKeySuffix = "address";
export const apartmentKeySuffix = "apartment";
export const cityKeySuffix = "city";
export const stateKeySuffix = "state";
export const zipKeySuffix = "zip";

export interface CustomFieldProps {
  field: CustomFieldConfig;
  index: number;
  form: FormInstance<any>;
  brand: FinancialBrand;
  nameSuffix?: string;
  readonly?: boolean;
}

export default function CustomField({
  field,
  index,
  form,
  brand,
  nameSuffix,
  readonly = false,
}: CustomFieldProps) {
  const FULL_WIDTH_COMPONENTS = [
    "textarea",
    "slider",
    "cloudinary",
    "radio",
    "checkbox",
    "multiselect",
  ];
  const [loaded, setLoaded] = useState(false);
  const { data: flow } = useFlow();
  const parsedProps =
    field.fieldProps && convertFunctionStrings(field.fieldProps);

  // If a nameSuffix is provided, append it to the field name.
  const fieldName = nameSuffix
    ? `${field.fieldKey}__${nameSuffix}`
    : field.fieldKey;

  // When the fieldName is assigned and the component has been rendered,
  // set the loaded state to true.
  useEffect(() => {
    if (!fieldName) return;
    setLoaded(true);
  }, [fieldName]);

  // After the field has been loaded, set its value to a defaultValue if
  // applicable and there is no previously-saved value.
  useEffect(() => {
    if (!loaded) {
      return;
    }
    const values = form.getFieldsValue();
    if (!values[fieldName]) {
      form.setFieldsValue({
        [fieldName]: field.fieldDefaultValue,
      });
    }
  }, [loaded]);

  const handleAddressSelect = useCallback(
    (address: string, addressFields?: AddressFields, isPopulated = false) => {
      const updatedValues = {
        [fieldName]: addressFields?.fullAddress || address,
        [`${fieldName}__${addressKeySuffix}`]:
          (isPopulated && addressFields?.address) || address,
        [`${fieldName}__${apartmentKeySuffix}`]:
          (isPopulated && addressFields?.subpremise) || "",
        [`${fieldName}__${cityKeySuffix}`]:
          (isPopulated && addressFields?.locality) || "",
        [`${fieldName}__${stateKeySuffix}`]:
          (isPopulated && addressFields?.state) || "",
        [`${fieldName}__${zipKeySuffix}`]:
          (isPopulated && addressFields?.postcode) || "",
      };
      form.setFieldsValue(updatedValues);
      form.setFieldsValue({});
    },
    [fieldName]
  );

  const inputField = () => {
    switch (field.fieldType) {
      case "address":
        return (
          <>
            <Form.Item name={fieldName} required className="ao-bl-select">
              <AddressAutocomplete
                placeholder={field.fieldPlaceholder}
                onAddressSelect={handleAddressSelect}
                icon={<HomeOutlined />}
                className="ao-bl-select"
                useFullAddress={true}
                {...parsedProps}
              />
            </Form.Item>
            <Form.Item
              name={`${fieldName}__${addressKeySuffix}`}
              style={{ display: "none" }}
            />
            <Form.Item
              name={`${fieldName}__${apartmentKeySuffix}`}
              style={{ display: "none" }}
            />
            <Form.Item
              name={`${fieldName}__${cityKeySuffix}`}
              style={{ display: "none" }}
            />
            <Form.Item
              name={`${fieldName}__${stateKeySuffix}`}
              style={{ display: "none" }}
            />
            <Form.Item
              name={`${fieldName}__${zipKeySuffix}`}
              style={{ display: "none" }}
            />
          </>
        );

      case "routingNumber":
        return (
          <Form.Item
            name={fieldName}
            valuePropName={"value"}
            // Use antd rules to display error message.
            // * Note: We're currently using our own custom disable logic as opposed to antd's, so this
            // * rule violation is ONLY for the error message, not for actually preventing the
            // * form from being submitted.
            rules={[
              () => ({
                // Use antd's validator to get value of field
                validator(_, value) {
                  // Return no validation errors for an empty input.
                  if (value.length === 0) {
                    return Promise.resolve();
                  }
                  // Return error without a message if input contains any underscores.
                  if (value.includes("_")) {
                    return Promise.reject();
                  }
                  // Return error with message if the value is filled out but not a valid routing number.
                  if (!isValidRoutingNumber(value)) {
                    return Promise.reject(
                      new Error("Invalid routing number. Please try again.")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <MaskedInput
              placeholder={field.fieldPlaceholder}
              mask="000000000"
              defaultValue={field.fieldDefaultValue?.toString()}
              className="ao-bl-input mt-2"
              disabled={readonly}
              {...parsedProps}
            />
          </Form.Item>
        );

      case "cloudinary": {
        const clConfig = field?.fieldConfiguration?.cloudinary;
        return (
          <CloudinaryUpload
            fieldName={fieldName}
            form={form}
            className={"ph-4 mv-6"}
            cloudName={clConfig?.cloudName}
            apiKey={clConfig?.apiKey}
            uploadPreset={clConfig?.uploadPreset}
            required={field.fieldRequired}
            clientAllowedFormats={clConfig?.clientAllowedFormats}
            {...parsedProps}
          />
        );
      }
      case "dollarInput":
        return (
          <Form.Item name={fieldName} valuePropName={"value"}>
            <InputNumber
              id={fieldName}
              className="w-100 ao-bl-input"
              min={field.fieldMin || 0}
              max={field.fieldMax}
              placeholder={field.fieldPlaceholder}
              stringMode={true}
              step="0.01"
              precision={2} //Rounds to nearest second decimal place.
              prefix={<DollarCircleOutlined className="site-form-item-icon" />}
              defaultValue={field.fieldDefaultValue as number}
              formatter={(value) => (value ? formatNumber(value) : "")}
              controls={false}
              disabled={readonly}
              {...parsedProps}
            />
          </Form.Item>
        );
      case "inputNumber":
        return (
          <Form.Item name={fieldName} valuePropName={"value"}>
            <InputNumber
              id={fieldName}
              className="w-100 ao-bl-input"
              min={field.fieldMin || 0}
              max={field.fieldMax}
              placeholder={field.fieldPlaceholder}
              stringMode={true}
              step="1"
              defaultValue={field.fieldDefaultValue as number}
              disabled={readonly}
              {...parsedProps}
            />
          </Form.Item>
        );
      case "checkbox":
        return (
          <div className="flex">
            <Form.Item
              name={fieldName}
              valuePropName="checked"
              className="mr-3 mb-6"
            >
              <Checkbox className="ao-lg-checkbox" {...parsedProps} />
            </Form.Item>
            <div>
              <p
                className={`${styles.textLinks} f-4 gray-8 lh-4 mt-1 mb-6`}
                dangerouslySetInnerHTML={{
                  __html: field.fieldText ?? "",
                }}
              />
            </div>
          </div>
        );
      case "date":
        return (
          <Form.Item
            name={fieldName}
            valuePropName={"value"}
            rules={[
              () => ({
                // Use antd's validator to get value of field
                validator(_, date) {
                  // Return no validation errors for an empty input
                  if (!date) {
                    return Promise.resolve();
                  }
                  const validationOptions =
                    field.fieldConfiguration?.date?.validation;
                  const formData = form.getFieldsValue();

                  // - VALIDATION CHECKS AGAINST SPECIFIED DATES IN JSON CONFIG -
                  // ***** Check that provided date is not earlier than a specified date *****
                  if (
                    validationOptions?.beforeDate &&
                    date.isBefore(dayjs(validationOptions.beforeDate))
                  ) {
                    return Promise.reject(
                      new Error(
                        `You must select a date after ${dayjs(
                          validationOptions.beforeDate
                        ).format("MM/DD/YYYY")}.`
                      )
                    );
                  }
                  // ***** Check that provided date is not later than a specified date *****
                  if (
                    validationOptions?.afterDate &&
                    date.isAfter(dayjs(validationOptions.afterDate))
                  ) {
                    return Promise.reject(
                      new Error(
                        `You must select a date before ${dayjs(
                          validationOptions.afterDate
                        ).format("MM/DD/YYYY")}.`
                      )
                    );
                  }

                  // - VALIDATION CHECKS AGAINST OTHER INPUTS ON THE CURRENT STEP -
                  const beforeFieldDate = validationOptions?.beforeFieldKey
                    ? formData[validationOptions?.beforeFieldKey]
                    : null;
                  const afterFieldDate = validationOptions?.afterFieldKey
                    ? formData[validationOptions?.afterFieldKey]
                    : null;

                  // ***** Check that provided date is not earlier than a specified input *****
                  if (beforeFieldDate && date.isBefore(beforeFieldDate)) {
                    return Promise.reject(new Error(`Invalid date range`));
                  }
                  // ***** Check that provided date is not later than a specified input *****
                  if (afterFieldDate && date.isAfter(afterFieldDate)) {
                    return Promise.reject(new Error("Invalid date range."));
                  }

                  // If all these checks pass, the entry is valid.
                  return Promise.resolve();
                },
              }),
            ]}
          >
            {field.fieldDefaultValue ? (
              <DatePicker
                className="w-100 ao-bl-picker"
                config={field?.fieldConfiguration?.date}
                disabled={readonly}
                // TODO:ACQR-3829 - Replace moment.
                // @ts-ignore
                defaultValue={dayjs(
                  field.fieldDefaultValue as string,
                  "MM/DD/YYYY"
                )}
                {...parsedProps}
              />
            ) : (
              <DatePicker
                className="w-100 ao-bl-picker"
                config={field?.fieldConfiguration?.date}
                // TODO:ACQR-3829 - Replace moment.
                // @ts-ignore
                defaultPickerValue={
                  field.fieldConfiguration?.date?.defaultPickerDate &&
                  dayjs(field.fieldConfiguration?.date?.defaultPickerDate)
                }
                {...parsedProps}
              />
            )}
          </Form.Item>
        );
      case "maskedInput":
        return (
          <Form.Item
            name={fieldName}
            valuePropName={"value"}
            rules={[
              () =>
                antdValidateRegex(
                  field.fieldRegexValidation?.regex,
                  field.fieldRegexValidation?.message,
                  true
                ),
            ]}
          >
            <MaskedInput
              placeholder={field.fieldPlaceholder}
              mask={field.fieldMask?.replaceAll("1", "0") || ""}
              defaultValue={field.fieldDefaultValue?.toString()}
              className="ao-bl-input mt-2"
              disabled={readonly}
              {...parsedProps}
            />
          </Form.Item>
        );
      case "multiselect":
        return (
          <Form.Item
            name={fieldName}
            valuePropName={"value"}
            rules={[
              {
                required: field.fieldRequired,
                message: "Please select at least one option.",
              },
            ]}
          >
            <Checkbox.Group
              className={`w-100${
                !field.fieldConfiguration?.multiselect?.inline
                  ? " ao-vrt-checkbox"
                  : ""
              }`}
              options={(field.fieldOptions ?? []).map((option) => ({
                label: option.label || option.text || "",
                value: option.value,
              }))}
              disabled={readonly}
              {...parsedProps}
            />
          </Form.Item>
        );
      case "ssn":
        return (
          <Form.Item
            name={fieldName}
            valuePropName={"value"}
            // Use antd rules to display error message.
            // * Note: We're currently using our own custom disable logic as opposed to antd's, so this
            // * rule violation is ONLY for the error message, not for actually preventing the
            // * form from being submitted.
            rules={[
              () => ({
                // Use antd's validator to get value of field
                validator(_, value) {
                  if (
                    !isValidSsn(value) && // ...if it isn't a valid ssn
                    value.length > 0 && // ...and the field isn't empty
                    value.slice(-1) !== "_" // ...and it is filled out (the last character isn't a "_")
                  ) {
                    return Promise.reject(
                      new Error(
                        "Invalid social security number. Please try again."
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <MaskedInput
              placeholder={field.fieldPlaceholder}
              mask="000-00-0000"
              defaultValue={field.fieldDefaultValue?.toString()}
              className="ao-bl-input mt-2"
              disabled={readonly}
            />
          </Form.Item>
        );
      case "autocomplete":
        return (
          <Form.Item
            name={fieldName}
            valuePropName={"value"}
            validateTrigger={"onBlur"}
            rules={[
              () => ({
                // Use antd's validator to get value of field.
                // * This validation check will be ignored if "allowFreeInput" is marked true.
                validator(_, value) {
                  if (
                    !field.fieldConfiguration?.autocomplete?.allowFreeInput &&
                    !isValidAutoComplete(value, field.fieldOptions) &&
                    value.length > 0
                  ) {
                    return Promise.reject(
                      new Error(
                        "Please select an option from the list (then change fields to clear this warning)"
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <AutoComplete
              options={field.fieldOptions ?? []}
              placeholder={field.fieldPlaceholder}
              defaultValue={field.fieldDefaultValue?.toString()}
              defaultActiveFirstOption={true}
              className="ao-bl-select"
              disabled={readonly}
              filterOption={(input, options) => {
                if (!options?.value || !options.label) {
                  return false;
                }
                return (
                  options.value.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  options.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              {...parsedProps}
            />
          </Form.Item>
        );
      case "select":
        return (
          <Form.Item
            name={fieldName}
            valuePropName={"value"}
            className="pointer"
          >
            <Select
              showSearch
              optionLabelProp="label" // Display the selection by the label, not the value
              filterOption={(input, options) => {
                if (!options?.value || !options.label) {
                  return false;
                }
                return (
                  options.value
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  options.label
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
              options={field.fieldOptions ?? []}
              notFoundContent={"No options found"}
              placeholder={field.fieldPlaceholder}
              defaultValue={field.fieldDefaultValue?.toString()}
              className="ao-bl-select"
              disabled={readonly}
            />
          </Form.Item>
        );
      case "slider":
        if (!field.fieldMin || !field.fieldMax) {
          return <></>;
        }
        return (
          <Form.Item name={fieldName} valuePropName={"value"}>
            <SliderWithDisplay
              key={fieldName}
              min={field.fieldMin}
              max={field.fieldMax}
              textColor={brand.primaryColor}
              sliderColor={brand.primaryColor}
              {...parsedProps}
            />
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item name={fieldName} valuePropName={"value"}>
            <Radio.Group>
              {field?.fieldOptions?.map((option: any, index) => (
                <div className="flex" key={option.value + index}>
                  <Radio
                    key={option.value}
                    value={option.value.toString()} // radio values must always be strings.
                    className="pv-2 ao-lg-radio"
                    disabled={readonly}
                    {...parsedProps}
                  >
                    <div
                      className="fwn gray-8"
                      dangerouslySetInnerHTML={{
                        __html: option.text ?? "",
                      }}
                    />
                  </Radio>
                </div>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      case "email":
        return (
          <Form.Item name={fieldName} valuePropName={"value"}>
            <Input
              placeholder={field.fieldPlaceholder}
              type="email"
              defaultValue={field.fieldDefaultValue?.toString()}
              className="ao-bl-input"
              disabled={readonly}
              {...parsedProps}
            />
          </Form.Item>
        );
      case "phone":
        return (
          <Form.Item
            name={fieldName}
            valuePropName={"value"}
            // required={false} // Requirement is handled by our own validator... not Antd's.
            rules={[
              () => ({
                // Use antd's validator to get value of field
                validator(_, value) {
                  if (
                    value &&
                    !isValidPhoneNumber(value.toString(), "US") && // if it isn't a valid phone number.
                    value.slice(-1) !== "_" // ...and it is filled out (the last character isn't a "_")
                  ) {
                    return Promise.reject(
                      new Error(
                        "Invalid number. Please try again or use a new number."
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <MaskedInput
              placeholder={
                field.fieldPlaceholder &&
                formatAsNationalPhoneNum(field.fieldPlaceholder?.toString())
              }
              mask="(000) 000-0000"
              className="ao-bl-input"
              disabled={readonly}
              {...parsedProps}
            />
          </Form.Item>
        );
      case "textarea": {
        const { TextArea } = Input;
        const taConfig = field.fieldConfiguration?.textarea;
        return (
          <Form.Item name={fieldName} valuePropName={"value"}>
            <TextArea
              placeholder={field.fieldPlaceholder}
              defaultValue={field.fieldDefaultValue?.toString()}
              autoSize={{
                minRows: taConfig?.minRows || 2,
                maxRows: taConfig?.maxRows || 5,
              }}
              showCount={taConfig?.showCount || true}
              maxLength={taConfig?.maxLength || 400}
              className="ao-bl-input b-primary mt-1"
              bordered={false}
              style={{ backgroundColor: "white" }}
              disabled={readonly}
              {...parsedProps}
            />
          </Form.Item>
        );
      }
      case "membershipEligibility": {
        // Sort the eligibilities by ascending sortOrder.
        const sortedEligibilities = [
          ...(flow?.membershipEligibilities ?? []),
        ].sort((a, b) => a.sortOrder - b.sortOrder);

        // Determine the input type based on the field configuration. Default to "select" if no configuration is provided or if field type is not "radio".
        const isRadio =
          field.fieldConfiguration?.membershipEligibility?.fieldType ===
          "radio";

        // Create radio options if the input type is radio.
        const createRadioOptions = (eligibilities: MembershipEligibility[]) =>
          eligibilities.map((eligiblity) => (
            <div key={eligiblity.id} className="flex">
              <Radio
                value={eligiblity.id}
                className="pv-2 ao-lg-radio"
                disabled={readonly}
                {...parsedProps}
              >
                <div
                  className="fwn gray-8"
                  dangerouslySetInnerHTML={{
                    __html: eligiblity.description ?? "",
                  }}
                />
              </Radio>
            </div>
          ));

        // Create select options if the input type is not radio.
        const createSelectOptions = (eligibilities: MembershipEligibility[]) =>
          eligibilities.map((eligiblity) => ({
            value: eligiblity.id,
            label: eligiblity.description,
          }));

        // Create the appropriate options based on the input type.
        const options = isRadio
          ? createRadioOptions(sortedEligibilities)
          : createSelectOptions(sortedEligibilities);

        // Return the appropriate input type.
        return (
          <Form.Item name={fieldName} valuePropName={"value"}>
            {isRadio ? (
              <Radio.Group>{options as ReactElement[]}</Radio.Group>
            ) : (
              <Select
                options={options as { value: string; label: string }[]}
                placeholder={field.fieldPlaceholder}
                defaultValue={field.fieldDefaultValue?.toString()}
                className="ao-bl-select"
                disabled={readonly}
                {...parsedProps}
              />
            )}
          </Form.Item>
        );
      }
      case "text":
      default:
        return (
          <Form.Item
            name={fieldName}
            valuePropName={"value"}
            rules={[
              antdValidateRegex(
                field.fieldRegexValidation?.regex,
                field.fieldRegexValidation?.message
              ),
            ]}
          >
            <Input
              placeholder={field.fieldPlaceholder}
              defaultValue={field.fieldDefaultValue?.toString()}
              className={`ao-bl-input ${readonly && `primary`}`}
              disabled={readonly}
              maxLength={field.maxLength}
              {...parsedProps}
            />
          </Form.Item>
        );
    }
  };

  return (
    <Row key={index}>
      <Col xs={24} md={22}>
        {field.fieldLabel ? (
          <label
            htmlFor={fieldName}
            dangerouslySetInnerHTML={{ __html: field.fieldLabel }}
          />
        ) : (
          <label htmlFor={fieldName}>{field.fieldLabel}</label>
        )}{" "}
      </Col>
      {/* Render a full-width component for specified components, but 2/3 width
      for the rest. */}
      <Col
        xs={24}
        sm={FULL_WIDTH_COMPONENTS.includes(field.fieldType) ? 24 : 16}
      >
        {inputField()}
      </Col>
    </Row>
  );
}
